<template>
  <div>
    <gql-table
      ref="agreementGqlTable"
      :fields="columns"
      :query="query"
      query-type="accounts"
      sort-by="signedAt"
      :sort-desc="true"
      :actions="actions"
      @confirm="onConfirm"
      @contract="onResendContract"
      @download="onDownload"
    >
      <template #cell(account_type_name)="data">
        {{ accountTypes[data.item.account_type_name] }}
      </template>

      <template #cell(account_id)="data">
        <b-row>
          <b-col>
            <b-link :to="{ name: 'account-detail', params: { id: data.item.account_id } }">
              {{ data.item.account_id }}
            </b-link>
            <risk-score-badge
              v-if="data.item.risk_score"
              class="mx-1"
              :score-results="data.item.risk_score_results"
              :score="data.item.risk_score"
            />
          </b-col>
          <b-col>
            <tags-list
              :tags="data.item.tags"
              :extras-tags="getAccountExtraTags(data.item)"
            />
          </b-col>
        </b-row>
      </template>

      <template #cell(deposit)="data">
        {{ formatCurrency(data.item.deposit, data.item.currency.label) }}
      </template>

      <template #cell(user)="data">
        <b-row>
          <b-col>
            <b-link :to="{ name: 'user-detail', params: { id: data.item.user.id } }">
              {{ data.item.user.firstName }} {{ data.item.user.lastName }}
              <risk-score-badge
                v-if="data.item.user.risk_score"
                class="mx-1"
                :score-results="data.item.user.risk_score_results"
                :score="data.item.user.risk_score"
              />
              <br>
              <small>{{ data.item.user.email }}</small>
            </b-link>
          </b-col>
          <b-col>
            <tags-list
              :tags="data.item.user.tags"
              :extras-tags="getUserExtraTags(data.item.user)"
              extras-variant="light-success"
            />
          </b-col>
        </b-row>
      </template>

      <template #cell(created_at)="data">
        {{ formatDateTime(data.item.createdAt) }}
      </template>

      <template #cell(signedAt)="data">
        {{ formatDateTime(data.item.signedAt) }}
      </template>

      <template #cell(confirmedAt)="data">
        {{ formatDateTime(data.item.confirmedAt) }}
      </template>

      <template #cell(state)="data">
        <b-badge :variant="`light-${stateVariants[data.item.state]}`">
          {{ data.item.state }}
        </b-badge>
        <b-badge variant="light-info">
          {{ data.item.is_active ? 'is active' : 'is inactive' }}
        </b-badge>
      </template>

      <template #cell(document_state)="data">
        <b-badge
          v-if="data.item.signedAt && data.item.confirmedAt"
          variant="light-success"
        >
          Completed
        </b-badge>
        <b-badge
          v-else-if="data.item.signedAt"
          variant="light-warning"
        >
          Wait for confirmation
        </b-badge>
        <b-badge
          v-else
          variant="light-danger"
        >
          Unsigned
        </b-badge>
      </template>

      <template #cell(link)="data">
        <b-link
          :href="`${pandaPrefix}${data.item.doc_id}`"
          target="_blank"
        >
          <feather-icon
            icon="FileIcon"
            class="ml-25"
            size="20"
          />
        </b-link>
      </template>
    </gql-table>
  </div>
</template>
<script>
import {
  BBadge,
  BCol, BLink, BRow,
} from 'bootstrap-vue'
import Swal from 'sweetalert2'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import GqlTable from '@/components/ui/gqltables/GqlTable.vue'
import { ACCOUNTS_SHORT, STATE_VARIANTS, PANDADOC_URL_PREFIX } from '@/enums/accounts'
import RiskScoreBadge from '@/components/ui/RiskScoreBadge.vue'
import TagsList from '@/components/page/TagsList.vue'
import { getAccountExtraTags } from '@/plugins/accountUtils'
import { formatCurrency, formatDateTime } from '@/plugins/formaters'
import { getUserExtraTags } from '@/plugins/userUtils'
import PandadocApi from '@/api/pandadoc'

const actions = [
  {
    text: 'Confirm contract and activate funded account',
    icon: 'ChevronsUpIcon',
    emit: 'confirm',
  },
  {
    text: 'Resend contract',
    icon: 'SendIcon',
    emit: 'contract',
  },
  {
    text: 'Download contract',
    icon: 'DownloadIcon',
    emit: 'download',
  },
]

const query = [
  'id',
  'account_id',
  'account_type_name',
  'account_subtype_name',
  'balance',
  'deposit',
  'createdAt',
  'start_datetime',
  'end_datetime',
  'close_datetime',
  'state',
  'is_active',
  'password',
  'investor_password',
  'doc_id',
  'signedAt',
  'confirmedAt',
  'risk_score',
  'risk_score_results',
  'phase_move_check',
  'equity_check',
  'rule_drawdown_type',
  'rule_first_withdrawal_after',
  'rule_inheritance',
  'rule_max_daily_drawdown',
  'rule_max_days',
  'rule_max_total_drawdown',
  'rule_min_profit',
  'rule_min_trading_days',
  'rule_special',
  'rule_withdrawal_after',
  'extras_tags',
  'comment',
  { order: { fields: ['id', 'order_number'] } },
  { parent: { fields: ['id', 'account_id'] } },
  { rootParent: { fields: ['id', 'account_id', { order: { fields: ['id', 'order_number'] } }] } },
  { currency: { fields: ['label'] } },
  { user: { fields: ['firstName', 'lastName', 'id', 'email', 'risk_score_results', 'risk_score', { tags: { fields: [{ tag: { fields: ['name', 'visible', 'color', 'description'] } }, 'createdAt'] } }] } },
  { tags: { fields: [{ tag: { fields: ['name', 'visible', 'color', 'description'] } }, 'createdAt'] } },
]
export default {
  components: {
    BBadge,
    BRow,
    TagsList,
    BCol,
    BLink,
    RiskScoreBadge,
    GqlTable,
  },
  data() {
    return {
      accountTypes: ACCOUNTS_SHORT,
      stateVariants: STATE_VARIANTS,
      pandaPrefix: PANDADOC_URL_PREFIX,
      actions,
      query,
      columns: [
        {
          name: 'searchString', label: 'Quick search', filterable: 'like', visible: false,
        },
        {
          name: 'account_type_name',
          label: 'Account type',
          filterable: { type: 'multienum', enum: Object.keys(ACCOUNTS_SHORT) },
        },
        {
          name: 'account_subtype_name',
          label: 'Account subtype',
          filterable: { type: 'eq', visible: false },
          default: 'funded',
          visible: false,
        },
        {
          name: 'account_id',
          label: 'Account id',
        },
        {
          name: 'deposit',
          label: 'Deposit',
        },
        {
          name: 'user',
          label: 'User',
          filterable: {
            type: 'entity', queryType: 'users', value: 'id', label: 'email', search: 'email',
          },
        },
        {
          name: 'createdAt',
          label: 'Created at',
        },
        {
          name: 'signedAt',
          label: 'Signed at',
        },
        {
          name: 'confirmedAt',
          label: 'Confirmed at',
        },
        {
          name: 'state',
          label: 'State',
          filterable: { type: 'multienum', enum: ['failed', 'completed', 'active'] },
        },
        {
          name: 'document_state',
          label: 'Document state',
        },
        {
          name: 'link',
          label: 'Document link',
        },
        {
          name: 'includeDocLink',
          label: 'Have doc link',
          filterable: { type: 'is', visible: false },
          default: true,
          visible: false,
        },
      ],
    }
  },
  methods: {
    getAccountExtraTags,
    formatCurrency,
    formatDateTime,
    getUserExtraTags,
    onConfirm(item) {
      Swal.fire({
        title: 'Are you sure?',
        text: 'Account will be activated',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
      }).then(result => {
        if (result.isConfirmed) {
          PandadocApi.activateAccount(item.id)
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Account activation',
                  icon: 'SendIcon',
                  text: 'Account was successfully activated',
                  variant: 'success',
                },
              })
              this.$refs.agreementGqlTable.reloadAll()
            })
        }
      })
    },
    onResendContract(item) {
      if (item.account_subtype_name !== 'funded' || item.confirmedAt) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Unable to proceed',
            icon: 'WarningIcon',
            text: 'Create contract is possible for funded and unconfirmed account only!',
            variant: 'danger',
          },
        })
        return
      }
      Swal.fire({
        title: 'Are you sure?',
        text: `Contract for account ${item.account_id} will be created.`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, create',
      }).then(result => {
        if (result.isConfirmed) {
          PandadocApi.postSendContract(item.id)
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Contract created',
                  icon: 'CheckCircleIcon',
                  text: 'Contract created',
                  variant: 'success',
                },
              })
            })
        }
      })
    },
    onDownload(item) {
      PandadocApi.downloadAgreement(item.id, item.user.email, item.account_id).catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Download failed',
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      })
    },
  },
}
</script>
